import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ImprintPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />
      <section className="p-16">
        <h1 id="imprint">Imprint</h1>
        <ul>
        <li>DIANA NAUMANN</li>
        <li>Viktoriastr. 3</li>
        <li>58636 Iserlohn</li>
        <li>Steuernummer: 01692558 7</li>
        <li>Inhaberin: Diana Naumann</li>
        <li><p>Bankverbindung: IBAN DE57 1101 0100 2462 4606 25, BIC SOBKDEBBXXX</p>
        </li>
        <li><p>Stand: 18.02.2020</p>
        </li>
        </ul>

      </section>
    </Layout>
  );
}

export default ImprintPage;
